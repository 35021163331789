import { format } from 'date-fns'

export const booleanFormatter = (value) => value === true ? "Yes" : (value === false ? "No" : "");

export const dateFormatter = (value) => value && format(new Date(value), 'yyyy-MM-dd HH:mm:ss (OOO)');

export const dayFormatter = (value) => value && format(new Date(value), 'yyyy-MM-dd');

export const timeFormatter = (value) => {
    if (value === null) {
        return null;
    }

    const hours = Math.floor(value / 1000 / 60 / 60);
    const minutes = Math.floor(value / 1000 / 60 % 60);
    const seconds =  Math.floor(value / 1000 % 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export const numberFormatter = (value, decimals, unit) => {
    if (value) {
        return `${value.toFixed(decimals)} ${unit}`;
    } else {
        return `${(0).toFixed(decimals)} ${unit}`;
    }
}

export const kcalFormatter = (value) => numberFormatter(value, 0, "kcal");
export const kjFormatter = (value) => numberFormatter(value, 0, "kJ");
export const gFormatter = (value) => numberFormatter(value, 2, "g");
export const mgFormatter = (value) => numberFormatter(value * 1000, 2, "mg");
export const µgFormatter = (value) => numberFormatter(value * 1000 * 1000, 2, "µg");

export const percentFormatter = (value) => {
    if (value !== undefined) {
        return `${value.toFixed(0)} %`;
    } else {
        return "";
    }
}
