import React, { Component } from 'react';

import Hero from "./Hero";
import Features from "./Features";
import Pricing from "./Pricing";

class Home extends Component {

    render() {
        return (
            <>
                <Hero />
                <Features />
                <Pricing user={this.props.user} />
            </>
        );
    }
}

export default Home;
