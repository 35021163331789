
import history from './history'

//
// actions
//

const get = (url, query, orderBy, order) => {
    const params = [];

    if (query) { params.push(`q=${query}`); }
    if (orderBy) { params.push(`orderBy=${orderBy}`); }
    if (order) { params.push(`order=${order}`); }

    if (params.length === 0) {
        return fetch(url);
    } else {
        return fetch(`${url}?${params.join("&")}`);
    }
};

const post = (url, data, raw) => {
    if (raw) {
        return fetch(url, {
            method: "POST",
            body: data
        });
    }

    return fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
};

const put = (url, data) => {
    return fetch(url, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
};

const deleTe = (url) => {
    return fetch(url, {
        method: "DELETE"
    });
};


//
// processing
//

const authorize = (response) => {
    if (!response.ok && response.status === 401) {
        history.push("/entrar");
        // TODO abort
    } else {
        return response;
    }
};

const verify = (response) => {
    if (!response.ok) {
        // TODO display error
        throw Error(`${response.status} ${response.statusText}`);
    }
    return response;
};

const parseJson = (response) => {
    if (response.status !== 204) {
        return response.json();
    } else {
        return null;
    }
};


//
// abstract crud
//

const crud = (endpoint) => {
    return {
        list:   (q, c, d) =>  get(endpoint, q, c, d)        .then(authorize).then(verify).then(parseJson),
        get:    (id) =>       get(`${endpoint}/${id}`)      .then(authorize).then(verify).then(parseJson),
        add:    (data) =>     post(endpoint, data)          .then(authorize).then(verify).then(parseJson),
        edit:   (id, data) => put(`${endpoint}/${id}`, data).then(authorize).then(verify).then(parseJson),
        remove: (id) =>       deleTe(`${endpoint}/${id}`)   .then(authorize).then(verify)
    };
}


//
// resources
//

export const user =     crud("/api/user");
export const client =   crud("/api/client");
export const recipe =   crud("/api/recipe");
export const food =     crud("/api/food");
export const allergen = crud("/api/allergen");

export const session = {
    endpoint: "/api/session",
    get:      () =>     get(session.endpoint)       .then(verify).then(parseJson),
    signin:   (data) => post(session.endpoint, data).then(verify).then(parseJson),
    signout:  () =>     deleTe(session.endpoint)    .then(verify)
};

export const file = {
    endpoint: "/api/file",
    get: (id) =>   get(`${file.endpoint}/${id}`)  .then(authorize).then(verify).then(parseJson),
    add: (data) => post(file.endpoint, data, true).then(authorize).then(verify).then(parseJson)
};

export const nutritionist = {
    endpoint: "/api/nutritionist",
    get:  () =>     get(`${nutritionist.endpoint}`)      .then(authorize).then(verify).then(parseJson),
    edit: (data) => put(`${nutritionist.endpoint}`, data).then(authorize).then(verify).then(parseJson),
};

export const billing = {
    endpoint: "/api/billing",
    get:  () =>     get(`${billing.endpoint}`)      .then(authorize).then(verify).then(parseJson),
    edit: (data) => put(`${billing.endpoint}`, data).then(authorize).then(verify).then(parseJson),
};
