import React, { Component } from 'react';
import { food } from '../../lib/api';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Form, {Input} from '../common/Form';
import ExpiredWall from "../ExpiredWall";

class FoodAdd extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            id: this.props.match.params.id,
            form: {
                name: "",
                edible_part: 100
            },
            loading: this.props.match.params.id ? true : false,
            error: false
        };

        this.state = this.defaultState;

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }

    componentDidMount() {
        if (this.state.id) {
            food.get(this.state.id)
                .then(food => this.setState({
                    form: food,
                    loading: false
                }))
                .catch(error => this.setState({
                    loading: true
                }));
        }
    }

    onChange(name, value) {
        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value.trim() === "" ? null : value  // empty is sent as null
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        const request = this.state.id ? food.edit(this.state.id, this.state.form) : food.add(this.state.form);

        request
            .then(food => {
                this.props.history.push("/alimentos");
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    onFormCancel(event) {
        this.props.history.push("/alimentos");
    }

    renderFields(name, fields) {
        return (
            <div className="box">
                {name &&
                <div className="subtitle has-text-primary mb-4">{name}</div>
                }
                <div className="columns is-multiline">
                    {fields.map((entry) => (
                        <div key={entry.id} className="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
                            <Input
                                type="number"
                                label={entry.name}
                                placeholder={entry.name}
                                value={this.state.form && this.state.form[entry.id]}
                                min="0"
                                step="any"
                                onChange={(value) => this.onChange(entry.id, value) }
                                error={this.state.error}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.user.plan === 'EXPIRED') {
            return <ExpiredWall />;
        }

        const action = this.state.id ? "Editar" : "Adicionar";

        if (this.state.loading) {
            return (
                <Page title={`${action} Alimento`}>
                    <Loading />
                </Page>
            );
        }

        const energia = [
            { id: "energia_kj",   name: "Energia (kJ)" },
            { id: "energia_kcal", name: "Energia (kcal)" }
        ];

        const composicao = [
            { id: "lipidos_g",             name: "Lípidos (g)" },
            { id: "hidratos_de_carbono_g", name: "Hidratos de Carbono (g)" },
            { id: "fibra_g",               name: "Fibra (g)" },
            { id: "proteinas_g",           name: "Proteínas (g)" },
            { id: "sal_g",                 name: "Sal (g)" },
            { id: "alcool_g",              name: "Álcool (g)" },
            { id: "agua_g",                name: "Água (g)" },
            { id: "acidos_organicos_g",    name: "Ácidos Orgânicos (g)" },
            { id: "colesterol_mg",         name: "Colesterol (mg)" }
        ];

        const lipidos = [
            { id: "acidos_gordos_saturados_g",       name: "Ácidos gordos saturados (g)" },
            { id: "acidos_gordos_monoinsaturados_g", name: "Ácidos gordos monoinsaturados (g)" },
            { id: "acidos_gordos_polinsaturados_g",  name: "Ácidos gordos polinsaturados (g)" },
            { id: "acido_linoleico_g",               name: "Ácido linoleico (g)" },
            { id: "acidos_gordos_trans_g",           name: "Ácidos gordos trans (g)" }
        ];

        const hidratos = [
            { id: "acucares_g",        name: "Açúcares (g)" },
            { id: "oligossacaridos_g", name: "Oligossacaáridos (g)" },
            { id: "amido_g",           name: "Amido (g)" }
        ];

        const vitaminas = [
            { id: "vitamina_a_µg",              name: "Vitamina A (µg)" },
            { id: "caroteno_µg",                name: "Caroteno (µg)" },
            { id: "vitamina_d_µg",              name: "Vitamina D (µg)" },
            { id: "alfa_tocoferol_mg",          name: "Alfa-tocoferol (mg)" },
            { id: "tiamina_mg",                 name: "Tiamina (mg)" },
            { id: "riboflavina_mg",             name: "Riboflavina (mg)" },
            { id: "niacina_mg",                 name: "Niacina (mg)" },
            { id: "equivalentes_de_niacina_mg", name: "Equivalentes de niacina (mg)" },
            { id: "triptofano_60_mg",           name: "Triptofano/60 (mg)" },
            { id: "vitamina_b6_mg",             name: "Vitamina B6 (mg)" },
            { id: "vitamina_b12_µg",            name: "Vitamina B12 (µg)" },
            { id: "vitamina_c_mg",              name: "Vitamina C (mg)" },
            { id: "folatos_µg",                 name: "Folatos (µg)" }
        ];

        const minerais = [
            { id: "cinza_g",     name: "Cinza (g)" },
            { id: "sodio_mg",    name: "Sódio (mg)" },
            { id: "potassio_mg", name: "Potássio (mg)" },
            { id: "calcio_mg",   name: "Cálcio (mg)" },
            { id: "fosforo_mg",  name: "Fósforo (mg)" },
            { id: "magnesio_mg", name: "Magnésio (mg)" },
            { id: "ferro_mg",    name: "Ferro (mg)" },
            { id: "zinco_mg",    name: "Zinco (mg)" },
            { id: "selenio_µg",  name: "Selénio (µg)" },
            { id: "iodo_µg",     name: "Iodo (µg)" }
        ];

        return (
            <Page title={`${action} Alimento`}>
                <Form submitLabel={action} cancelLabel="Cancelar" onSubmit={this.onFormSubmit} onCancel={this.onFormCancel}>
                    <div className="columns">
                        <div className="column is-half">
                            <Input
                                label="Nome"
                                placeholder="Nome"
                                required={true}
                                value={this.state.form?.name || ""}
                                onChange={(value) => this.onChange("name", value) }
                                error={this.state.error}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
                            <Input
                                type="number"
                                label="Parte edível (%)"
                                placeholder="Parte edível (%)"
                                required={true}
                                value={this.state.form?.edible_part}
                                min={0}
                                max={100}
                                step={1}
                                onChange={(value) => this.onChange("edible_part", value) }
                                error={this.state.error}
                            />
                        </div>
                    </div>
                    <article className="message is-info">
                        <div className="message-body">
                            <b>Valores por 100g de parte edível.</b>
                        </div>
                    </article>
                    {this.renderFields("Energia", energia)}
                    {this.renderFields("Composição", composicao)}
                    {this.renderFields("Lípidos", lipidos)}
                    {this.renderFields("Hidratos de Carbono", hidratos)}
                    {this.renderFields("Vitaminas", vitaminas)}
                    {this.renderFields("Minerais", minerais)}
                </Form>
            </Page>
        );
    }
}

export default FoodAdd;
