import React, { Component } from 'react';

class Features extends Component {

    render() {
        return (
            <>
                <section id="funcionalidades" className="section is-medium has-text-centered">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-3">
                                <span className="icon is-large">
                                    <i className="fas fa-2x fa-shapes"></i>
                                </span>
                                <p className="title is-4 has-text-dark mb-6">Alimentos e Alergénios</p>
                                <p className="subtitle has-text-grey">1330 alimentos e 14 alergénios prontos a usar.</p>
                            </div>
                            <div className="column is-3">
                                <span className="icon is-large">
                                    <i className="fas fa-2x fa-square-root-alt"></i>
                                </span>
                                <p className="title is-4 has-text-dark mb-6">Cálculos Automáticos</p>
                                <p className="subtitle has-text-grey">Não perca tempo a calcular os valores nutricionais nem a descobrir a parte edível dos alimentos.</p>
                            </div>
                            <div className="column is-3">
                                <span className="icon is-large">
                                    <i className="fas fa-2x fa-share-alt"></i>
                                </span>
                                <p className="title is-4 has-text-dark mb-6">Partilha de fichas técnicas</p>
                                <p className="subtitle has-text-grey">Faça download da ficha técnica em formato PDF.</p>
                            </div>
                            <div className="column is-3">
                                <span className="icon is-large">
                                    <i className="fas fa-2x fa-wifi"></i>
                                </span>
                                <p className="title is-4 has-text-dark mb-6">Acessível em todo o lado</p>
                                <p className="subtitle has-text-grey">Todas as fichas técnicas estão disponíveis online com segurança.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Features;
