import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer has-background-dark has-text-light">
                <div className="container">
                    <div className="columns">
                        <div className="column is-3">
                            <p className="mb-2 has-text-weight-bold">DESENVOLVIDO POR</p>
                            <span className="icon mr-1">
                                <i className="fas fa-building"></i>
                            </span>
                            Bitstream, Unipessoal Lda<br/>
                            <span className="icon mr-1">
                                <i className="fas fa-credit-card"></i>
                            </span>
                            NIPC 515808245<br/>
                            <span className="icon mr-1">
                                <i className="fas fa-globe"></i>
                            </span>
                            <a href="https://bitstream.pt/" target="_blank" rel="noreferrer" className="has-text-light">
                                https://bitstream.pt/
                            </a>
                            <br/>
                            <span className="icon mr-1">
                                <i className="fas fa-envelope"></i>
                            </span>
                            <a href="mailto:info@bitstream.pt" target="_blank" rel="noreferrer" className="has-text-light">
                                info@bitstream.pt
                            </a>
                        </div>
                        <div className="column is-3">
                            <p className="mb-2 has-text-weight-bold">CONTACTOS</p>

                            <span className="icon mr-1">
                                <i className="fas fa-globe"></i>
                            </span>
                            <a href="https://nutrit.pt/" target="_blank" rel="noreferrer" className="has-text-light">
                                https://nutrit.pt/
                            </a>
                            <br/>
                            <span className="icon mr-1">
                                <i className="fas fa-envelope"></i>
                            </span>
                            <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer" className="has-text-light">
                                info@nutrit.pt
                            </a>
                            <br/>
                            {/*<span className="icon mr-1">*/}
                            {/*    <i className="fas fa-phone"></i>*/}
                            {/*</span>*/}
                            {/*<a href="tel:+351964158903" target="_blank" rel="noreferrer" className="has-text-light">*/}
                            {/*    +351 964 158 903*/}
                            {/*</a>*/}
                            {/*<br/>*/}
                        </div>
                        <div className="column is-3">
                            <p className="mb-2 has-text-weight-bold">REFERÊNCIAS</p>
                            <b>Fonte dos valores nutricionais:</b><br/>
                            Tabela da Composição de Alimentos. Instituto Nacional de Saúde Doutor Ricardo Jorge, I. P.- INSA. v 6.0 - 2023
                            <a href="https://portfir-insa.min-saude.pt/" target="_blank" rel="noreferrer" className="has-text-light">
                                <span className="icon">
                                    <i className="fas fa-external-link-alt"></i>
                                </span>
                            </a>
                        </div>
                        <div className="column is-2 is-offset-1">
                            <Link to="/privacidade" className="has-text-light">
                                Política de Privacidade
                            </Link>
                            <br/>
                            <Link to="/termos" className="has-text-light">
                                Termos e Condições
                            </Link>
                            <br/>
                            <br/>
                            2025 © Bitstream<br/>
                            Todos os direitos reservados.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
