import React, { Component } from 'react';
import { recipe, client, food, allergen } from '../../lib/api';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Form, { Input, TextArea, Select, File } from '../common/Form';
import { set } from "lodash";
import FoodForm from './FoodForm';
import AllergenForm from './AllergenForm';
import { v4 as uuidv4 } from 'uuid';
import ExpiredWall from "../ExpiredWall";

class RecipeAdd extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            id: this.props.match.params.id,
            form: {
                name: "",
                image: null,
                portions: 1,
                food: [],
                allergens: [],
                instructions: "",
                security: "Deve garantir que a temperatura interna do alimento atinge, no mínimo, os 65°C.",
                client_id: ""
            },
            clients: null,
            food: null,
            allergens: null,
            loading: true,
            error: false
        };

        this.state = this.defaultState;

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }

    componentDidMount() {
        if (this.state.id) {
            Promise.all([recipe.get(this.state.id), client.list(), food.list(), allergen.list()])
                .then(([ recipe, clients, food, allergens ]) => {
                    this.setState({
                        form: {
                            name: recipe.name,
                            image: recipe.image,
                            portions: recipe.portions,
                            food: (recipe.food || []).map(food => { return { id: uuidv4(), ...food }; }),
                            allergens: (recipe.allergens || []).map(allergen => { return { id: uuidv4(), ...allergen }; }),
                            instructions: recipe.instructions,
                            security: recipe.security,
                            client_id: recipe.client_id
                        },
                        clients: clients,
                        food: food,
                        allergens: allergens,
                        loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        clients: null,
                        food: null,
                        allergens: null,
                        loading: true
                    });
                });
        } else {
            Promise.all([client.list(), food.list(), allergen.list()])
                .then(([ clients, food, allergens ]) => {
                    this.setState({
                        clients: clients,
                        food: food,
                        allergens: allergens,
                        loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        clients: null,
                        food: null,
                        allergens: null,
                        loading: true
                    });
                });
        }
    }

    onChange(name, value) {
        this.setState(prevState => {
            return {
                form: set({ ...prevState.form }, name, value),
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();
        
        const request = this.state.id ? recipe.edit(this.state.id, this.state.form) : recipe.add(this.state.form);

        request
            .then(recipe => {
                this.props.history.push(`/receitas/${recipe.id}`);
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    onFormCancel(event) {
        this.props.history.goBack();
    }

    render() {
        if (this.props.user.plan === 'EXPIRED') {
            return <ExpiredWall />;
        }

        const action = this.state.id ? "Editar" : "Adicionar";

        if (this.state.loading) {
            return (
                <Page title="Adicionar Receita">
                    <Loading />
                </Page>
            );
        }

        return (
            <Page title={`${action} Receita`}>
                <Form submitLabel={action} cancelLabel="Cancelar" onSubmit={this.onFormSubmit} onCancel={this.onFormCancel}>
                    <Input
                        label="Nome"
                        placeholder="Nome"
                        required={true}
                        value={this.state.form.name}
                        onChange={(value) => this.onChange("name", value)}
                        style={{ maxWidth: '400px' }}
                    />
                    <Select
                        label="Cliente"
                        placeholder="Escolha um cliente..."
                        value={this.state.form.client_id}
                        options={this.state.clients && this.state.clients.map((client) => { return { value: client.id, label: client.name }; })}
                        onChange={(value) => this.onChange("client_id", value)}
                        style={{ maxWidth: '400px' }}
                    />
                    <File
                        label="Imagem"
                        required={false}
                        value={this.state.form.image}
                        onChange={(value) => this.onChange("image", value)}
                    />
                    <Input
                        type="number"
                        label="Porções"
                        placeholder="Porções"
                        required={true}
                        value={this.state.form.portions}
                        onChange={(value) => this.onChange("portions", value && parseInt(value))}
                        style={{ maxWidth: '120px' }}
                    />
                    <div className="field mt-5">
                        <label className="label">Ingredientes</label>
                        <FoodForm
                            value={this.state.form.food}
                            onChange={(value) => this.onChange("food", value)}
                            food={this.state.food}
                            user={this.props.user}
                        />
                    </div>
                    <div className="field mt-5 mb-5">
                        <label className="label">Alergénios</label>
                        <AllergenForm
                            value={this.state.form.allergens}
                            onChange={(value) => this.onChange("allergens", value)}
                            allergens={this.state.allergens}
                            user={this.props.user}
                        />
                    </div>
                    <TextArea
                        label="Realização"
                        placeholder="Escreva a realização..."
                        rows={6}
                        value={this.state.form.instructions}
                        onChange={(value) => this.onChange("instructions", value)}
                    />
                    <TextArea
                        label="Segurança Alimentar"
                        placeholder="Escreva as notas de segurança alimentar..."
                        rows={3}
                        value={this.state.form.security}
                        onChange={(value) => this.onChange("security", value)}
                    />
                    <article className="message">
                        <div className="message-body">
                            <b>Notas:</b>
                            <br/>
                            Os valores nutricionais das receitas são calculados de acordo com a <a href="https://portfir-insa.min-saude.pt/" target="_blank" rel="noreferrer">Tabela da Composição de Alimentos - Instituto Nacional de Saúde Doutor Ricardo Jorge, I. P.- INSA. v 6.0 - 2023</a>.
                            <br/>
                            As técnicas de preparação e a qualidade dos produtos podem afectar os valores nutricionais da receita.
                            <br/>
                            Para capitações podem consultar as <a href="http://www.dge.mec.pt/sites/default/files/Esaude/oere.pdf" target="_blank" rel="noreferrer">Orientações sobre Ementas e Refeitórios Escolares</a>.
                        </div>
                    </article>
                </Form>
            </Page>
        );
    }
}

export default RecipeAdd;
