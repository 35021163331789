import React, { Component } from 'react';
import { session } from '../../lib/api';
import { Redirect } from "react-router-dom";


class SignIn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: "",
                password: ""
            },
            error: false
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        session.signin(this.state.form)
            .then(user => {
                this.props.onSignIn();
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    render() {
        const errorClass = this.state.error ? "is-danger" : "";

        if (this.props.user) {
            return (
                <Redirect to={{ pathname: "/after-sign-in" }} />
            );
        }

        return (
            <section id="registar" className="section is-fullscreen is-flex is-justify-content-center is-align-items-center">
                <div className="box p-5 has-background-white-bis" style={{maxWidth: '400px'}}>
                    <h1 className="title has-text-primary has-text-centered mb-5">Entrar</h1>

                    <form name="contact" method="POST" onSubmit={this.onFormSubmit}>
                        <div className="field">
                            <p className="control has-icons-left">
                                <input className={"input " + errorClass} name="email" type="email" required
                                       placeholder="E-mail" value={this.state.form.email}
                                       onChange={this.onFormChange}/>
                                <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left">
                                <input className={"input " + errorClass} name="password" type="password" required
                                       placeholder="Palavra passe" value={this.state.form.password}
                                       onChange={this.onFormChange}/>
                                <span className="icon is-small is-left">
                                    <i className="fas fa-lock"></i>
                                </span>
                            </p>
                        </div>
                        <div className="field">
                            <p className="control">
                                <button type="submit" className="button is-primary is-fullwidth">Entrar</button>
                            </p>
                        </div>
                    </form>

                    <div className="separator mt-5 mb-5 has-text-grey">Entrar com rede social</div>

                    <p className="buttons is-grouped-centered">
                        <a href='/api/social/google' className="button is-fullwidth has-text-white"
                           style={{backgroundColor: '#EA4335'}}>
                            <span className="icon">
                              <i className="fab fa-google"></i>
                            </span>
                            <span>Entrar com o Google</span>
                        </a>
                        <a href='/api/social/facebook' className="button is-fullwidth has-text-white"
                           style={{backgroundColor: '#0866ff'}}>
                            <span className="icon">
                              <i className="fab fa-facebook"></i>
                            </span>
                            <span>Entrar com o Facebook</span>
                        </a>
                        <a href='/api/social/linkedin' className="button is-fullwidth has-text-white"
                           style={{backgroundColor: '#0077B5'}}>
                            <span className="icon">
                              <i className="fab fa-linkedin"></i>
                            </span>
                            <span>Entrar com o Linkedin</span>
                        </a>
                    </p>
                </div>
            </section>
        );
    }
}

export default SignIn;
