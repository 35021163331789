import React, { Component } from 'react';
import { recipe } from '../../lib/api';
import { dateFormatter } from '../../lib/formatter';
import Loading from '../common/Loading';
import Page from '../common/Page';
import PageTable from '../common/PageTable';
import ExpiredWall from "../ExpiredWall";

class RecipeList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            recipes: null,
            loading: true
        };

        this.refresh = this.refresh.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        recipe.list()
            .then(recipes => this.setState({
                recipes: recipes,
                loading: false
            }))
            .catch(error => this.setState({
                recipes: null,
                loading: true
            }));
    }

    onAdd(id) {
        this.props.history.push(`/receitas/adicionar`);
    }

    onEdit(id) {
        this.props.history.push(`/receitas/${id}/editar`);
    }

    onDelete(id, row) {
        if (!window.confirm(`Deseja mesmo apagar a receita "${row.name}"?`)) {
            return;
        }

        recipe.remove(id)
            .then(json => {
                this.refresh();
            })
            .catch(error => {
                console.log(error);
            });
    }

    onRowClick(id) {
        this.props.history.push(`/receitas/${id}`);
    }

    render() {
        if (this.props.user.plan === 'EXPIRED') {
            return <ExpiredWall />;
        }

        if (this.state.loading) {
            return (
                <Page title="Receitas">
                    <Loading />
                </Page>
            );
        }

        const isTrial = this.props.user.plan === 'TRIAL' || this.props.user.plan === 'EXPIRED';

        const isDisabled = isTrial && this.state.recipes.length >= 3;

        const actions = (
            <div className="buttons is-right">
                <button title="Adicionar Receita" className="button is-light is-primary" disabled={isDisabled} onClick={this.onAdd}>
                    <span className="icon">
                        <i className="fas fa-plus"></i>
                    </span>
                    <span>Adicionar</span>
                </button>
            </div>
        );

        const inlineActions = (value, row) => (
            <div className="buttons is-right">
                <button title="Editar" className="button is-small is-light is-info" onClick={() => this.onEdit(value, row)}>
                    <span className="icon is-small">
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                </button>
                <button title="Apagar" className="button is-small is-light is-danger" onClick={() => this.onDelete(value, row)}>
                    <span className="icon is-small">
                        <i className="far fa-trash-alt"></i>
                    </span>
                </button>
            </div>
        );

        return (
            <PageTable
                title="Receitas"
                className="is-hoverable"
                columns={[
                    { key: "name",        title: "Nome",            searchable: true,  sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left",                         rowClassName: "" },
                    { key: "client.name", title: "Cliente",         searchable: true,  sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left is-hidden-mobile",        rowClassName: " is-hidden-mobile" },
                    { key: "created_at",  title: "Data de Criação", searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left is-fit is-hidden-mobile", rowClassName: "is-nowrap is-fit is-hidden-mobile", formatter: dateFormatter },
                ]}
                rows={this.state.recipes}
                actions={actions}
                inlineActions={inlineActions}
                onRowClick={this.onRowClick}
                orderBy="name"
                orderAscending={true}
            />
        );
    }
}

export default RecipeList;
