import React, { Component } from 'react';
import { withRouter } from "react-router";

import { isAfter, formatDistance } from 'date-fns/esm';
import { pt } from 'date-fns/locale';
import { Link } from 'react-router-dom';

class CallToSubscribe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true
        };

        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    render() {
        if (!this.state.open || !this.props.user || this.props.user?.plan !== "TRIAL") {
            return null;
        }

        var content = null;
        var timeRemaining = null;
        
        const now = new Date();
        const end = new Date(this.props.user.end_at);

        if (isAfter(now, end)) {
            timeRemaining = "já terminou";
        } else {
            timeRemaining = `vai terminar dentro de ${formatDistance(now, end, { locale: pt })}`;
        }

        if (this.props.user?.plan === "TRIAL") {
            content = (
                <div className="columns is-vcentered">
                    <div className="column is-8">
                        <b>O seu período de teste {timeRemaining}</b>.<br/>
                        Para utilizar este serviço sem limitações necessita de criar uma subscrição.
                    </div>
                    <div className="column is-4">
                        <Link to='/config/subscrever' className="button is-dark">
                            <span className="icon is-small">
                                <i className="fas fa-arrow-circle-right"></i>
                            </span>
                            <span>Subscrever</span>
                        </Link>
                    </div>
                </div>
            )
        }

        return (
            <div className="notification mb-0 is-warning" style={{ borderRadius: '0px' }}>
                <button className="delete" onClick={this.onClose}></button>
                <div className="container">
                    {content}
                </div>
            </div>
        );
    }
}

export default withRouter(CallToSubscribe);
