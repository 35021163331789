import React, { Component } from 'react';
import Loading from '../common/Loading';
import Page from '../common/Page';
import { session, nutritionist, billing } from '../../lib/api';
import { Link } from 'react-router-dom';
import { dayFormatter } from '../../lib/formatter';

class Config extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            nutritionist: null,
            billing: null,
            loading: true
        };
    }

    componentDidMount() {
        Promise.all([ session.get(), nutritionist.get(), billing.get() ])
            .then(([ user, nutritionist, billing ]) => {
                this.setState({
                    user: user,
                    nutritionist: nutritionist,
                    billing: billing,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    user: null,
                    nutritionist: null,
                    billing: null,
                    loading: true
                });
            });
    }

    renderUser() {
        return (
            <div>
                <div className="subtitle has-text-primary mb-4">Utilizador</div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Nome:</th>
                            <td>{this.state.user.name}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Email:</th>
                            <td>{this.state.user.email}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderNutritionist() {
        return (
            <div>
                <div className="mb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="subtitle has-text-primary mb-0">Nutricionista</div>
                    <Link to='/config/nutritionist' className="button is-light is-info is-small">
                        <span className="icon is-small">
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span>Editar</span>
                    </Link>
                </div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Nome:</th>
                            <td>{this.state.nutritionist.name}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Descrição:</th>
                            <td style={{ whiteSpace: 'pre' }}>{this.state.nutritionist.description}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <span className="icon is-small has-text-info">
                        <i className="fas fa-circle-info"></i>
                    </span>
                    <span className="ml-1 is-size-7 is-italic">Esta informação será incluída no rodapé das fichas técnicas.</span>
                </div>
            </div>
        );
    }

    renderBillingData() {
        return (
            <div>
                <div className="mb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="subtitle has-text-primary mb-0">Dados de faturação</div>
                    <Link to='/config/billing' className="button is-light is-info is-small">
                        <span className="icon is-small">
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span>Editar</span>
                    </Link>
                </div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Nome:</th>
                            <td>{this.state.billing.name}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">NIF / NIPC:</th>
                            <td>{this.state.billing.vat}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Morada:</th>
                            <td>{this.state.billing.address}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Código Postal:</th>
                            <td>{this.state.billing.postcode}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Localidade:</th>
                            <td>{this.state.billing.location}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">País:</th>
                            <td>{this.state.billing.country}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <span className="icon is-small has-text-info">
                        <i className="fas fa-circle-info"></i>
                    </span>
                    <span className="ml-1 is-size-7 is-italic">Esta informação será usada na emissão de faturas e recibos futuros.</span>
                </div>
            </div>
        );
    }

    getSubscriptionName(plan) {
        switch (plan) {
            case 'TRIAL': return "Experimental";
            case 'SUBSCRIBED': return "Profissional";
            case 'EXPIRED': return "Expirado";
            default: return "Não Definido";
        }
    }

    renderSubscription() {
        return (
            <div>
                <div className="mb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="subtitle has-text-primary mb-0">Subscrição</div>
                    {this.state.user.plan !== 'SUBSCRIBED' &&
                    <Link to='/config/subscrever' className="button is-primary is-small">
                        <span className="icon is-small">
                            <i className="fas fa-arrow-circle-right"></i>
                        </span>
                        <span>Subscrever</span>
                    </Link>
                    }
                </div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Plano:</th>
                            <td>{this.getSubscriptionName(this.state.user.plan)}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Data de inicio:</th>
                            <td>{dayFormatter(new Date(this.state.user.start_at))}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Data de fim:</th>
                            <td>{dayFormatter(new Date(this.state.user.end_at))}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <span className="icon is-small has-text-info">
                        <i className="fas fa-circle-info"></i>
                    </span>
                    <span className="ml-1 is-size-7 is-italic">Para cancelar a subscrição envie um email para <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.</span>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <Page>
                    <Loading />
                </Page>
            );
        }

        return (
            <Page id="config" title="Configurações">
                <div className="columns is-desktop is-multiline">
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderUser()}
                        </div>
                        <div className="box">
                            {this.renderNutritionist()}
                        </div>
                    </div>
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderBillingData()}
                        </div>
                    </div>
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderSubscription()}
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Config;
