import React, { Component } from 'react';
import { food } from '../../lib/api';
import Loading from '../common/Loading';
import Page from '../common/Page';
import PageTable from '../common/PageTable';
import ExpiredWall from "../ExpiredWall";

class FoodList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            food: null,
            loading: true
        };

        this.refresh = this.refresh.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        food.list()
            .then(food => this.setState({
                food: food,
                loading: false
            }))
            .catch(error => this.setState({
                food: null,
                loading: true
            }));
    }

    onAdd(id) {
        this.props.history.push(`/alimentos/adicionar`);
    }

    onEdit(id) {
        this.props.history.push(`/alimentos/${id}/editar`);
    }

    onDelete(id, row) {
        if (!window.confirm(`Deseja mesmo apagar o alimento "${row.name}"?`)) {
            return;
        }

        food.remove(id)
            .then(json => {
                this.refresh();
            })
            .catch(error => {
                console.log(error);
            });
    }

    onRowClick(id) {
        this.props.history.push(`/alimentos/${id}`);
    }

    render() {
        if (this.props.user.plan === 'EXPIRED') {
            return <ExpiredWall />;
        }

        if (this.state.loading) {
            return (
                <Page title="Alimentos">
                    <Loading />
                </Page>
            );
        }

        const numberFormatter = (value, decimals, unit) => {
            if (value) {
                return `${value.toFixed(decimals)} ${unit}`;
            } else {
                return `${(0).toFixed(decimals)} ${unit}`;
            }
        }

        const kcalFormatter = (value) => numberFormatter(value, 0, "kcal");
        const gFormatter = (value) => numberFormatter(value, 1, "g");
        const mgFormatter = (value) => numberFormatter(value, 0, "mg");

        const actions = (
            <div className="buttons is-right">
                <button title="Adicionar Alimento" className="button is-light is-primary" onClick={this.onAdd}>
                    <span className="icon">
                        <i className="fas fa-plus"></i>
                    </span>
                    <span>Adicionar</span>
                </button>
            </div>
        );

        const inlineActions = (value, row) => (
            row.user_id ?
            <div className="buttons is-right">
                <button title="Editar" className="button is-small is-light is-info" onClick={() => this.onEdit(value, row)}>
                    <span className="icon is-small">
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                </button>
                <button title="Apagar" className="button is-small is-light is-danger" onClick={() => this.onDelete(value, row)}>
                    <span className="icon is-small">
                        <i className="far fa-trash-alt"></i>
                    </span>
                </button>
            </div> : null
        );

        return (
            <PageTable
                title="Alimentos"
                className="is-hoverable"
                columns={[
                    { key: "name",                  title: "Nome",                abbreviation: null,       searchable: true,  sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left",                                 rowClassName: "ellipsis" },
                    { key: "energia_kcal",          title: "Energia",             abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: kcalFormatter },
                    { key: "lipidos_g",             title: "Lípidos",             abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "hidratos_de_carbono_g", title: "Hidratos de Carbono", abbreviation: "Hidratos", searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "fibra_g",               title: "Fibra",               abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "proteinas_g",           title: "Proteínas",           abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "sal_g",                 title: "Sal",                 abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "alcool_g",              title: "Álcool",              abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "agua_g",                title: "Água",                abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "acidos_organicos_g",    title: "Ácidos Orgânicos",    abbreviation: "Ácidos",   searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "colesterol_mg",         title: "Colesterol",          abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: mgFormatter },
                    // { key: "source",                title: "Fonte",               abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile" },
                ]}
                rows={this.state.food}
                actions={actions}
                inlineActions={inlineActions}
                onRowClick={this.onRowClick}
                orderBy="name"
                orderAscending={true}
            />
        );
    }
}

export default FoodList;
