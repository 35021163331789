import React, { Component } from 'react';
import { billing } from '../../lib/api';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Form, { Input } from '../common/Form';

class ConfigBilling extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            form: {
                name: "",
                vat: "",
                address: "",
                postcode: "",
                location: "",
                country: ""
            },
            error: false,
            loading: false // true
        };

        this.state = this.defaultState;

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }

    componentDidMount() {
        billing.get()
            .then(billing => this.setState({
                form: {
                    name:     billing.name,
                    vat:      billing.vat,
                    address:  billing.address,
                    postcode: billing.postcode,
                    location: billing.location,
                    country:  billing.country
                },
                loading: false
            }))
            .catch(error => this.setState({
                error: true,
                loading: true
            }));
    }

    onChange(name, value) {
        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        billing.edit(this.state.form)
            .then(billing => {
                this.props.history.push("/config");
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    onFormCancel(event) {
        this.props.history.push("/config");
    }

    render() {
        if (this.state.loading) {
            return (
                <Page title="Editar dados de faturação">
                    <Loading />
                </Page>
            );
        }

        return (
            <Page title="Editar dados de faturação">
                <Form submitLabel="Editar" cancelLabel="Cancelar" onSubmit={this.onFormSubmit} onCancel={this.onFormCancel}>
                    <Input
                        label="Nome"
                        placeholder="Nome"
                        value={this.state.form.name}
                        onChange={(value) => this.onChange("name", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="NIF / NIPC"
                        placeholder="NIF / NIPC"
                        value={this.state.form.vat}
                        onChange={(value) => this.onChange("vat", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="Morada"
                        placeholder="Morada"
                        value={this.state.form.address}
                        onChange={(value) => this.onChange("address", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="Código Postal"
                        placeholder="Código Postal"
                        value={this.state.form.postcode}
                        onChange={(value) => this.onChange("postcode", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="Localidade"
                        placeholder="Localidade"
                        value={this.state.form.location}
                        onChange={(value) => this.onChange("location", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="País"
                        placeholder="País"
                        value={this.state.form.country}
                        onChange={(value) => this.onChange("country", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                </Form>
            </Page>
        );
    }
}

export default ConfigBilling;
