import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { TypeAnimation } from "react-type-animation";

import recipe from '../../img/hero.png';

class Hero extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shake: false
        };
    }

    render() {
        const animation = (
            <TypeAnimation
                 sequence={[
                     1000,
                     ' rápido.',
                     1000,
                     ' equilibradas.',
                     1000,
                     ' sem esforço.',
                     3000,
                     () => { this.setState({ shake: true }); },
                     1000,
                     () => { this.setState({ shake: false }); },
                     10000
                 ]}
                 repeat={Infinity}
                 speed={60}
                 cursor={true}
            />
        );

        return (
            <>
                <section id="hero" className="hero is-medium">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <p className="title is-1 has-text-dark mb-6" style={{ fontSize: '2.3rem' }}>Crie fichas técnicas{animation}</p>
                                    <p className="subtitle">O Nutrit permite criar <b>refeições</b> e <b>fichas técnicas</b> de forma fácil e equilibrada tendo por base a informação nutricional dos mais variados alimentos.</p>
                                    <div className="buttons">
                                        <NavLink to='/registar' className="button is-primary" style={ this.state.shake ? { animation: 'horizontal-shaking 0.25s linear 3' } : {}}>Experimente grátis!</NavLink>
                                    </div>
                                </div>
                                <div className="column is-5 is-offset-1 is-hidden-mobile" style={{ perspective: '1000px' }}>
                                    <img src={recipe} alt="" style={{ borderRadius: '8px', boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px', transform: 'rotateY(-6deg) rotateZ(1deg)' }}/>
                                </div>
                                <div className="column is-5 is-offset-1 mt-6 is-hidden-tablet has-text-centered" style={{ perspective: '1000px' }}>
                                    <img src={recipe} style={{ maxWidth: '66%', borderRadius: '8px', boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px' }} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Hero;
