import React, { Component } from 'react';
import Page from '../common/Page';

class CheckoutError extends Component {

    render() {
        return (
            <Page id="checkout-success" title="Não foi possível realizar a sua subscrição.">
                <p className={"subtitle"}>Por favor tente outra vez.</p>
                <p>Caso detecte alguma irregularidade contacte-nos através do email <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.</p>
            </Page>
        );
    }
}

export default CheckoutError;
