import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from './common/Page';

class ExpiredWall extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page>
                <div className="notification is-warning">
                    <h2>A sua subscrição expirou</h2>
                    <span className="is-size-6">Para continuar a utilizar o Nutrit necessita de renovar a sua subscrição.</span><br/>
                    <br/>
                    <Link to='/config/subscrever' className="button is-dark">
                        <span className="icon is-small">
                            <i className="fas fa-arrow-circle-right"></i>
                        </span>
                        <span>Subscrever</span>
                    </Link>
                </div>
            </Page>
        );
    }
}

export default ExpiredWall;
