import React, { Component } from 'react';
import Page from '../common/Page';


class ConfigSubscribe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    async onCheckout() {
        this.setState({
            loading: true
        });

        const response = await fetch(`/api/subscription`, { method: 'POST' });
        const session = await response.json();

        // TODO redirect via router
        window.location = session.redirectUrl;
    }

    renderGreen(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderYellow(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-warning" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderRed(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-times has-text-danger" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderInfinity(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check-double has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderTrialPanel() {
        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Experimental</div>
                    <div className="mb-3">
                        <span className={"is-size-2 has-text-weight-bold mr-2"}>Grátis</span>/<span className="is-size-6 has-text-weight-normal ml-1">7 dias</span>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Experimente sem qualquer custo ou compromisso.
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderYellow("1 entidade") }
                { this.renderYellow("3 receitas / fichas técnicas") }
                { this.renderYellow("2 ingredientes") }
                { this.renderGreen("1330 alimentos com 42 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Adicionar novos alimentos") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                <div className="panel-block" style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className={`button is-fullwidth ${this.state.loading ? "is-loading" : ""}`} disabled>
                        Subscrever
                    </button>
                </div>
            </nav>
        );
    }

    renderProPanel() {
        const color = "success";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Profissional</div>
                    <div className="mb-0">
                        <span className={`is-size-2 has-text-weight-bold mr-2 has-text-${color}`}>10€</span>/<span className="is-size-6 has-text-weight-normal ml-1">mês *</span>
                    </div>
                    <div className="mb-3">
                        <div className={`tag has-text-weight-bold is-${color}`}>Faturado 120€ anualmente *</div>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para profissionais, empresas e instituições que criam fichas técnicas para uma ou mais entidades (por exemplo nutricionistas, câmaras municipais, juntas de freguesia, restaurantes, ginásios, hospitais, escolas, creches e lares).
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1330 alimentos com 42 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Adicionar novos alimentos") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                <div className="panel-block" style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className={`button is-fullwidth has-text-weight-bold is-${color} ${this.state.loading ? "is-loading" : ""}`} onClick={() => this.onCheckout()}>
                        Subscrever
                    </button>
                </div>
            </nav>
        );
    }

    renderCompanyPanel() {
        const color = "info";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Corporativo</div>
                    <div className="mb-3">
                        <span className={`is-size-6 has-text-weight-bold mr-2`}>Sob Consulta</span>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para empresas com múltiplos profissionais que podem gerir as fichas técnicas colaborativamente.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Por favor entre em contato connosco<br/>
                        através do email <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.
                    </div>
                </div>
                { this.renderGreen("Múltiplos utilizadores em colaboração") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1330 alimentos com 42 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Adicionar novos alimentos") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                <div className="panel-block">
                    <button className={`button is-fullwidth is-${color} ${this.state.loading ? "is-loading" : ""}`} disabled>
                        Sob Consulta
                    </button>
                </div>
            </nav>
        );
    }

    render() {
        return (
            <Page id="subscribe" title="Subscrever">
                <div className="columns" style={{ alignItems: 'flex-end' }}>
                    <div className="column">
                        {this.renderTrialPanel()}
                    </div>
                    <div className="column">
                        {this.renderProPanel()}
                    </div>
                    <div className="column">
                        {this.renderCompanyPanel()}
                    </div>
                </div>
                <div>
                    * Aos valores apresentados acresce IVA à taxa legal de 23%.
                </div>
            </Page>
        );
    }
}

export default ConfigSubscribe;
